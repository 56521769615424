import { disablePageScroll } from 'scroll-lock';
import { toggleMenu, smoothScroll, animFront, observeElements, submitForm } from './index';

document.addEventListener('DOMContentLoaded', () => {
  if (document.body.classList.contains('body--armax')) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    setTimeout(() => {
      disablePageScroll(document.documentElement);
      toggleMenu();
      smoothScroll();
      animFront();
      observeElements();
      submitForm();
    }, 150);
  }
});
